<template>
    <v-container fluid>
        <v-row>
            <v-col class="text-end" cols="12">
                <kurcc-user-options/>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0" cols="6">
                <v-row>
                    <v-col class="pa-0" cols="6" md="5" xl="4">
                        <v-col cols="12">
                            <kurcc-inspectors-analytics/>
                        </v-col>
                        <v-col cols="12">
                            <kurcc-analytics-with-fixed-date-range analytic-type="inspections"/>
                        </v-col>
                        <v-col cols="12">
                            <kurcc-analytics-with-fixed-date-range analytic-type="visitors"/>
                        </v-col>
                    </v-col>
                    <v-col cols="6" md="7" xl="8">
                        <v-card min-height="500">
                            <v-card-title>{{ $vuetify.lang.t('$vuetify.pages.dashboard.grades') }}</v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6">
                <kurcc-category-analytics-pie-cart/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "KurccDashboardPage",
    components: {
        KurccCategoryAnalyticsPieCart: () => import('@/modules/dashboard/components/KurccCategoryAnalyticsPieChart'),
        KurccInspectorsAnalytics: () => import('@/modules/dashboard/components/KurccInspectorsAnalytics'),
        KurccAnalyticsWithFixedDateRange: () => import('@/modules/dashboard/components/KurccAnalyticsWithFixedDateRange'),
        KurccUserOptions: () => import('@/modules/app/components/KurccUserOptions')
    }
}
</script>
